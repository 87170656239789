
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-12",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    dataChart: Object,
    tanggal: String,
    title: String,
  },
  setup(props) {
    const color = ref(props.chartColor);

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const series =
      props.dataChart?.series != null ? props.dataChart.series : [];
    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "line",
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        offsetY: 10,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: props.dataChart?.categories,
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },

      yaxis: {
        y: 0,
        offsetX: 0,
        offsetY: 0,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        type: "solid",
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val + " Berita";
          },
        },
      },
      grid: {
        padding: {
          top: -10,
          bottom: -10,
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    return {
      chartOptions,
      series,
    };
  },
});
