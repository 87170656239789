
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default defineComponent({
  name: "DyanamicTableComponent",
  components: {
    Datatable,
  },
  props: {
    widgetClasses: String,
    title: String,
    subtitle: String,
    data_body: Array,
    data_head: Array,
    tableKey: {
      default: 0,
      type: Number,
    },
    total_data: {
      default: 0,
      type: Number,
    },
    itemsPerPage: {
      default: 0,
      type: Number,
    },
  },
  setup() {
    return {};
  },
});
