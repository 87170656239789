import ApiService from "@/core/services/ApiService";

interface IScoreCard {
    media_cetak: number;
    media_tv: number;
    news: number;
    radio: number;
    total: number;
}
interface ITable {
    account: string;
    count: string;
}
interface ITrend {
    series: [];
    categories: [];
}

export { IScoreCard, ITrend, ITable };

export const getScorecard = async () => {
    const response = await ApiService.get("/dashboard/scorecard");
    return response.data;
};
export const getDailyTrend = async (payload) => {
    const response = await ApiService.post("/streams/trend/daily", { data: payload });
    return response.data;
};
export const getMonthlyTrend = async (payload) => {
    const response = await ApiService.post("/streams/trend/monthly", { data: payload });
    return response.data;
};
export const getTopMedia = async (payload) => {
    const response = await ApiService.post("/streams/top-media", { data: payload });
    return response.data;
};