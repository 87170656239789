
import { defineComponent, onMounted, ref } from "vue";
import moment from "moment";
import { useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import Linechart from "@/components/Dashboard/linechart_component.vue";
import Barchart from "@/components/Dashboard/barchart_component.vue";
import DyanamicTableComponent from "@/components/Dashboard/topmedia.vue";
import { useStore } from "vuex";
import {
  IScoreCard,
  getScorecard,
  getDailyTrend,
  ITrend,
  ITable,
  getMonthlyTrend,
  getTopMedia,
} from "./dashboard";
export default defineComponent({
  name: "dashboard",
  components: {
    StatisticsWidget5,
    DyanamicTableComponent,
    Linechart,
    Barchart,
    // MixedWidget7,
  },
  setup() {
    const route = useRoute();
    const keyLine = ref(0);
    const keyBar = ref(0);
    const tableKey = ref(0);
    const store = useStore();
    const role = store.getters.currentUser.data.role;
    const dataScorecard = ref<IScoreCard>({
      media_cetak: 0,
      media_tv: 0,
      news: 0,
      radio: 0,
      total: 0,
    });
    const dataDailyTrend = ref<ITrend>({
      series: [],
      categories: [],
    });
    const dataDailyAuthor = ref<ITrend>({
      series: [],
      categories: [],
    });
    const dataMonthlyTrend = ref<ITrend>({
      series: [],
      categories: [],
    });

    const dataTopMedia = ref<Array<ITable>>([]);

    const data_head = ref([
      {
        name: "Nama Media",
        key: "account",
        sortable: true,
      },
      {
        name: "Jumlah Berita",
        key: "count",
        sortable: true,
      },
    ]);

    const is_loading = ref(false);
    const tanggal = ref("");
    const tanggalMonth = ref("");

    const fetchData = async () => {
      const payload = {
        start_date: moment().subtract(6, "days").format("YYYY-MM-DD 00:00:00"),
        end_date: moment().format("YYYY-MM-DD 23:59:59"),
      };

      const payloadMonth = {
        start_date: moment().subtract(11, "months").format("YYYY-MM"),
        end_date: moment().format("YYYY-MM"),
      };
      tanggal.value =
        moment().subtract(6, "days").format("DD MMM YYYY") +
        " - " +
        moment().format("DD MMM YYYY");
      tanggalMonth.value =
        moment().subtract(12, "months").format("YYYY MMM") +
        " - " +
        moment().format("YYYY MMM");
      is_loading.value = true;
      const resScoreCard = await getScorecard();
      const resDailyTrend = await getDailyTrend(payload);
      const resMonthlyTrend = await getMonthlyTrend(payloadMonth);
      const resTopik = await getTopMedia(payload);

      if (role == 88 || role == 1) {
        const payloadAuthor = {
          start_date: moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD 00:00:00"),
          end_date: moment().format("YYYY-MM-DD 23:59:59"),
          groupby: "author",
        };

        await getDailyTrend(payloadAuthor).then((result) => {
          dataDailyAuthor.value = result;
        }).catch((err) => {
          dataDailyAuthor.value = {series: [], categories: []};
        });;
      }

      dataScorecard.value = resScoreCard;
      dataDailyTrend.value = resDailyTrend;
      dataMonthlyTrend.value = resMonthlyTrend;
      dataTopMedia.value = resTopik;
      is_loading.value = false;
      keyLine.value += 1;
      keyBar.value += 1;
      tableKey.value += 1;
    };

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      fetchData();
    });

    return {
      dataScorecard,
      fetchData,
      dataDailyTrend,
      dataMonthlyTrend,
      keyLine,
      tanggal,
      tanggalMonth,
      keyBar,
      dataTopMedia,
      data_head,
      tableKey,
      dataDailyAuthor,
      role,
    };
  },
});
