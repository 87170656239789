import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d47ff0d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = {
  class: "col-xl-2",
  style: {"width":"20%"}
}
const _hoisted_3 = {
  class: "col-xl-2",
  style: {"width":"20%"}
}
const _hoisted_4 = {
  class: "col-xl-2",
  style: {"width":"20%"}
}
const _hoisted_5 = {
  class: "col-xl-2",
  style: {"width":"20%"}
}
const _hoisted_6 = {
  class: "col-xl-4",
  style: {"width":"20%"}
}
const _hoisted_7 = { class: "row gy-5 g-xl-8" }
const _hoisted_8 = { class: "col-xxl-4" }
const _hoisted_9 = { class: "col-xxl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsWidget5 = _resolveComponent("StatisticsWidget5")!
  const _component_DyanamicTableComponent = _resolveComponent("DyanamicTableComponent")!
  const _component_Linechart = _resolveComponent("Linechart")!
  const _component_Barchart = _resolveComponent("Barchart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "media/icons/duotune/general/gen032.svg",
          color: "primary",
          "icon-color": "white",
          title: _ctx.dataScorecard.media_cetak.toString(),
          description: "Total Media Cetak Hari Ini"
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8 bg-warning-bar",
          "svg-icon": "media/icons/duotune/general/gen032.svg",
          color: "warning",
          "icon-color": "white",
          title: _ctx.dataScorecard.news.toString(),
          description: "Total Media Online Hari Ini"
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8 bg-success-bar",
          "svg-icon": "media/icons/duotune/general/gen032.svg",
          color: "success",
          "icon-color": "white",
          title: _ctx.dataScorecard.media_tv.toString(),
          description: "Total Media TV Hari Ini"
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "media/icons/duotune/general/gen032.svg",
          color: "danger",
          "icon-color": "white",
          title: _ctx.dataScorecard.radio.toString(),
          description: "Total Media Radio Hari Ini"
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "media/icons/duotune/general/gen032.svg",
          color: "info",
          "icon-color": "white",
          title: _ctx.dataScorecard.total.toString(),
          description: "Total Berita Hari Ini"
        }, null, 8, ["title"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_DyanamicTableComponent, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
          title: "Top Media",
          subtitle: "7 Hari Terakhir",
          data_body: _ctx.dataTopMedia,
          data_head: _ctx.data_head,
          tableKey: _ctx.tableKey
        }, null, 8, ["data_body", "data_head", "tableKey"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_Linechart, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "230",
          dataChart: _ctx.dataDailyTrend,
          key: _ctx.keyLine,
          tanggal: _ctx.tanggal,
          title: 'Berita 7 Hari Terakhir'
        }, null, 8, ["dataChart", "tanggal"]),
        _createVNode(_component_Barchart, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "230",
          key: _ctx.keyBar,
          dataChart: _ctx.dataMonthlyTrend,
          tanggal: _ctx.tanggalMonth
        }, null, 8, ["dataChart", "tanggal"])
      ])
    ]),
    (_ctx.role == 1 || _ctx.role == 88)
      ? (_openBlock(), _createBlock(_component_Linechart, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "230",
          dataChart: _ctx.dataDailyAuthor,
          key: _ctx.keyLine,
          tanggal: _ctx.tanggal,
          title: 'Tren Input Berita User'
        }, null, 8, ["dataChart", "tanggal"]))
      : _createCommentVNode("", true)
  ], 64))
}