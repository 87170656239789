import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_5 = { class: "card-body py-3" }
const _hoisted_6 = { class: "table-responsive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.subtitle), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Datatable, {
          "table-data": _ctx.data_body,
          "table-header": _ctx.data_head,
          "enable-items-per-page-dropdown": false,
          key: _ctx.tableKey,
          total: _ctx.total_data
        }, {
          "cell-account": _withCtx(({ row: stream }) => [
            _createTextVNode(_toDisplayString(stream.account), 1)
          ]),
          "cell-count": _withCtx(({ row: stream }) => [
            _createTextVNode(_toDisplayString(stream.count), 1)
          ]),
          _: 1
        }, 8, ["table-data", "table-header", "total"])
      ])
    ])
  ], 2))
}