import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body p-0 justify-content-between flex-column overflow-hidden" }
const _hoisted_2 = { class: "px-9 pt-9 pb-3" }
const _hoisted_3 = { class: "me-2" }
const _hoisted_4 = { class: "fw-bolder text-gray-800 d-block fs-3" }
const _hoisted_5 = { class: "text-gray-400 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.tanggal), 1)
        ])
      ]),
      _createVNode(_component_apexchart, {
        options: _ctx.chartOptions,
        series: _ctx.series,
        type: "line",
        height: _ctx.chartHeight
      }, null, 8, ["options", "series", "height"])
    ])
  ], 2))
}